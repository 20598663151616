import Rectangle_yellow from '../../../static/images/Rectangle_yellow.png'
import { funFacts } from "../../../static/AssetImports";
import Finds from "./Finds";
import Gets from "./Gets";
import Saves from "./Saves";
import Rates from "./Rates";
const { img3, img2, img1, img4 } = funFacts;


const FunFacts = () => {
	return (
		<section className="fun-bg" style={{ width: "100%" }}>
			<img id="dark_vector" src={Rectangle_yellow} alt="" />
			<h4 className="funfacts-heading header">
				The Average LocalStaysz's
				<span className="coloured-text"> User</span>
			</h4>
			<div className="main-carousel">
				<Finds image={img3} />
				<Saves image={img1} />
				<Gets image={img2} />
				<Rates image={img4} />
			</div>
		</section>
	);
};
export default FunFacts;
