import React from "react";
import Verified from "./Verified";
import FooterHeader from "./FooterHeader";
import Merit from "./Merit";

import footer_logo from "../../../static/images/logo.png"
import box1 from "../../../static/images/box1.png"
import box2 from "../../../static/images/box2.png"
import box3 from "../../../static/images/box3.png"
import box4 from "../../../static/images/box4.png"
import sponsor from "../../../static/images/sponsor.png"
import cor_sign from "../../../static/images/cor_sign.png"
import xcelsz from "../../../static/images/xcelsz.png"
import Rectangle_dark from '../../../static/images/Rectangle_dark.png'


import FacebookIcon from '../../../static/images/facebook.png';
import TelegramIcon from '../../../static/images/telegram.png';
import { subFooter } from "../../../static/AssetImports";
const {
	verified1,
	verified2,
	verified3,
	verified4,
	verified5,
	verified6,
	verified7,
	logo,
	badge
} = subFooter;
const SubFooter = () => {
	const verfiedAgent = [
		{ logo: verified2, alt: "verified2" },
		{ logo: verified5, alt: "verified5" },
		{ logo: verified6, alt: "verified6" },
		{ logo: verified7, alt: "verified7" }
	];
	const memberOf = [
		{ logo: verified1, alt: "verified1" },
		{ logo: verified3, alt: "verified3" },
		{ logo: verified4, alt: "verified4" }
	];
	return (
		<div className="sub-footer">
			{/* <FooterHeader /> */}
			<img id="dark_vector" src={Rectangle_dark} alt="" />
			<a href="/" className="brand-logo">
			<img className="logo" src={footer_logo} alt="logo" />
			</a>
			<p className="header">Occupying a property made easy ....</p>
			<div className="footer-grid">
				<img className="box" src={box1} alt="box1" />
				<img className="box" src={box3} alt="box3" />
				<img className="box" src={box2} alt="box2" />
				<img className="box" src={box4} alt="box4" />
				<p className="win-tag">You Win</p>
			</div>

			<div className="subcribe-tag">
				<div className="container">
					<div className="text">
					
					</div>
					<div className="input-btn">
						<input type="text" placeholder="Enter email" />
						<button className="go">GO</button>
					</div>
				</div>
			</div>
			<div className="follow-us">
				<div className="container subfoot-brand">
					<p>Follow us </p>
					<a href="https://www.facebook.com/XcelszPropertySolutions" title="facebook">
						<img className="social-icon" src={FacebookIcon} alt="social-links" />
					</a>
					<a href="https://t.me/+VVWxJnP0ffw6eXNs" title="telegram">
						<img className="social-icon" src={TelegramIcon} alt="social-links" />
					</a>
				</div>
			</div>

			<div className="sponsor">
				<img className="sponsor-img" src={sponsor} alt="sponsor"/>
			</div>

			<div className="solution">
				<div className="solu1">
					<p>LOCALSTAYZ IS AN</p> 
					<img src={xcelsz} alt="" />
					<p>SOLUTION</p>
				</div>
				<div className="solu-res">
					<img src={cor_sign} alt="" />
					<p>2020 - 2023</p>
				</div>
			</div>
		</div>
	);
};
export default SubFooter;
