import CarouselItem from "./CarouselItem";
import { carousel } from "../../../static/AssetImports";

const {caro1} = carousel;
const Carousel = () => {

	return (
		<section className="splide">
			<div className="splide__track">
				<ul className="splide__list">
					<CarouselItem
						imgWidth="80%"
						imgHeight="100%"
						appendedText="BE EASY AND STRESS-FREE."
						imgSrc={caro1}
						divClass=""
						textClass=""
					/>
				</ul>
			</div>
		</section>
	);
};
export default Carousel;
