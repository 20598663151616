import React from "react";
import { makeAccordion } from "../../utilities/makeNavlinks";
import sideNavLinks from "../../../static/navbarData";
const SideNav = () => {
	return (
		<div className="sidenav white-text" id="mobile-demo">
			<ul className="collapsible">{makeAccordion(sideNavLinks)}</ul>
		</div>
	);
};
export default SideNav;
