import React from "react";
import CookiesBanner from "./CookiesBanner";

//components
import SubFooter from "./SubFooter";
import Tribute from "./Tribute";
const Footer = () => {
  return (
    <>
      <div className="center-align footer ">
        <SubFooter />
        {/* <Tribute /> */}
        <CookiesBanner />
      </div>
    </>
  );
};
export default Footer;
