import $ from "jquery";
import Flickity from "./flickity.pkgd";
import Splide from "@splidejs/splide";
import M from "materialize-css/dist/js/materialize.min.js";

const initComps = () => {
	//Initialize all materialize js components
	M.AutoInit();

	//Init RollDown Navbar
	const navBarRoll = document.querySelector(".roll-down-nav");
	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 50 ||
			document.documentElement.scrollTop > 50
		) {
			navBarRoll.classList.add("showNav");
			// navBarRoll.style.position = "fixed";
			// navBarRoll.style.zIndex = 10;
		} else {
			navBarRoll.classList.remove("showNav");
			// navBarRoll.style.position = "relative";
			// navBarRoll.styles.zIndex = 10;
		}
	}

	//Enable dropdown on hover
	let navbar = document.querySelectorAll(".dropdown-trigger");
	let instances = M.Dropdown.init(navbar, {
		hover: true,
		coverTrigger: false,
		alignment: "center"
	});

	//Init funfacts
	let elem = document.querySelector(".main-carousel");
	let flkty = new Flickity(elem, {
		contain: true,
		prevNextButtons: false,
		wrapAround: false,
		adaptiveHeight: true,
		pauseAutoPlayOnHover: false
	});

	//Init carousel
	let elems = document.querySelectorAll(".splide");
	new Splide(elems[0], {
		direction: "ltr",
		height: "650px",
		arrows: false,
		type: "loop",
		pagination: true
	}).mount();

	//Init cookieBanner
	const cookiesBanner = document.querySelector(".cookies-banner");
	const acceptCookies = document.querySelectorAll(".accept-cookies")[0];
	const acceptCookies2 = document.querySelectorAll(".accept-cookies")[1];
	setTimeout(() => {
		if (!localStorage.getItem("seenCookiesBanner")) {
			cookiesBanner.classList.add("showBanner");
		}
	}, 1000);
	acceptCookies.addEventListener("click", () => {
		localStorage.setItem("seenCookiesBanner", true);
		cookiesBanner.classList.remove("showBanner");
	});
	acceptCookies2.addEventListener("click", () => {
		localStorage.setItem("seenCookiesBanner", true);
		cookiesBanner.classList.remove("showBanner");
	});

	//Init back to top
	const scrollToTopButton = document.querySelector(".top-btn");
	const scrollFunc = () => {
		if (window.scrollY > 50) {
			scrollToTopButton.className = "top-btn show";
		} else {
			scrollToTopButton.className = "top-btn hide";
		}
	};
	window.addEventListener("scroll", scrollFunc);
	const scrollToTop = () => {
		const c = document.documentElement.scrollTop || document.body.scrollTop;
		if (c > 0) {
			window.requestAnimationFrame(scrollToTop);
			window.scrollTo(0, c - c / 10);
		}
	};
	scrollToTopButton.onclick = function (e) {
		e.preventDefault();
		scrollToTop();
	};

	//check Necessary cookies
	document.getElementById("necessary").setAttribute("checked", "checked");
	document.getElementById("preferences").setAttribute("checked", "checked");
	document.getElementById("marketting").setAttribute("checked", "checked");
	document.getElementById("statistics").setAttribute("checked", "checked");
	document.getElementById("necessary").setAttribute("disabled", "disabled");
};
export default initComps;
