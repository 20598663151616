import logo from "./images/home/logo-white.png";
import badge from "./images/partnerships/insignia.png";
import verified1 from "./images/partnerships/realtor-removebg-preview.png";
import verified2 from "./images/partnerships/loop.png";
import verified3 from "./images/partnerships/GREDAlogo2-removebg-preview.png";
import verified4 from "./images/partnerships/logo-300x138.png";
import verified5 from "./images/partnerships/meqasa-logo-4.png";
import verified6 from "./images/partnerships/2020_MLS_FINAL_BLUE_TRANSPARENT.png";
import verified7 from "./images/partnerships/shortlethomes.png";
// subfooter Assets
// Social Media Nav assets
import facebook from "./images/social/facebook.png";
import twitter from "./images/social/twitter.png";
import instagram from "./images/social/instagram.png";
import mail from "./images/social/envelope.png";
import snapchat from "./images/social/snapchat.svg";
import tiktok from "./images/social/tiktok.svg";
import whatsapp from "./images/social/whatsapp.png";
import telegram from "./images/social/telegram.svg";
import pinterest from "./images/social/pinterest.svg";
import linkedIn from "./images/social/linkedin.png";
import youtube from "./images/social/youtube.png";
//funfacts Assets
import img3 from "./images/funfacts/finds.png";
import img2 from "./images/funfacts/gets.png";
import img1 from "./images/funfacts/saves.png";
import img4 from "./images/funfacts/rateus.png";
//Carousel assets
import caro1 from "./images/carouselimgs/undraw_Meditating_re_aiqa.png";
import caro2 from "./images/carouselimgs/undraw_play_time_7k7b.png";
import caro3 from "./images/carouselimgs/undraw_quality_time_wiyl.png";
import caro4 from "./images/carouselimgs/undraw_relaxing_walk_mljx.png";

const subFooter = {
  verified1,
  verified2,
  verified3,
  verified4,
  verified5,
  verified6,
  verified7,
  logo,
  badge,
};
const socialMedia = {
  facebook,
  twitter,
  instagram,
  tiktok,
  whatsapp,
  youtube,
  pinterest,
  telegram,
  snapchat,
  mail,
  linkedIn,
};
const funFacts = { img3, img2, img1, img4 };
const carousel = { caro1, caro2, caro3, caro4 };

export { funFacts, carousel, socialMedia, subFooter };
