const SocialIcon = ({ icon, alt, classNames, href }) => {
  return (
    <div className={classNames.join(" ")}>
      {" "}
      <a target="_blank" href={href}>
        <img src={icon} alt={alt} />
      </a>
    </div>
  );
};
export default SocialIcon;
