import React, { useEffect, Component } from "react";
import initComps from "./lib/js/initComps"; //Initialize Js Components
import { Link } from "react-router-dom";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Components
import Carousel from "./components/home/carousel/Carousel";
import Funfacts from "./components/home/funfacts/FunFacts";
import Cta from "./components/home/cta/Cta";
import Home from "./components/Home";
//Base components

import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import CookiesBanner from "./components/layout/footer/CookiesBanner";
import ScrollToTop from "./components/layout/footer/ScrollToTop";
import HowItWorks from "./components/HowItWorks";
import Loading from "./components/Loading";
import WhatsIncluded from "./components/WhatsIncluded";
import AboutOurServices from "./components/AboutOurServices";
import GettingStarted from "./components/GettingStarted";
import WhyItWorks from "./components/WhyItWorks";
import FAQs from "./components/FAQs";
import AboutOurFees from "./components/AboutOurFees";

export default function App() {
	useEffect(initComps, [Routes]);
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route
					path="about-our-services"
					element={<AboutOurServices />}
				/>
				<Route path="whats-included" element={<WhatsIncluded />} />
				<Route path="why-it-works" element={<WhyItWorks />} />
				<Route path="getting-started" element={<GettingStarted />} />
				<Route path="about-our-fees" element={<AboutOurFees />} />
				<Route path="faqs" element={<FAQs />} />
			</Routes>
			<ScrollToTop />
			<Footer />
		</BrowserRouter>
	);
}
