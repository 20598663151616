const Finds = ({ image, alt, classNames }) => {
	return (
		<div className="carousel-cell" style={{ width: "100%" }}>
			<section className="funfacts">
				<div className="funfacts-left-text">
					<h3>Finds</h3>
				</div>
				<div className="funfacts-center">
					<img width="70%" height="90%" src={image} alt="img1" />
				</div>
				<div className="funfacts-right-text">
					<p>
						Preferred Property, Tenants, Buyers and/or Investors
						under 90 days.
					</p>
				</div>
			</section>
		</div>
	);
};
export default Finds;
