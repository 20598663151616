const Rates = ({ image, alt, classNames }) => {
	return (
		<div className="carousel-cell" style={{ width: "100%" }}>
			<section className="funfacts">
				<div className="funfacts-left-text">
					<h3>Rates</h3>
				</div>
				<div className="funfacts-center">
					<img width="70%" height="85%" src={image} alt="img1" />
				</div>
				<div className="funfacts-right-text">
					<p>
						Us 4.3 stars. See what they are{" "}
						<span className="coloured-text">saying.</span>
					</p>
				</div>
			</section>
		</div>
	);
};
export default Rates;
