import CheckBox from "../../utilities/CheckBox";
import CheckedBox from "../../utilities/CheckBox";
import SubscribeBtn from "../../utilities/SubscribeBtn";
const CookiesBanner = () => {
	return (
		<div className="cookies-banner">
			<p className="cookie-banner-text">
				We use cookies on this site to enhance your user experience by
				personalizing content and ads, providing social media features
				and analysing trafic. You consent to our cookies if you continue
				to use our website.
			</p>
			<div className="container row">
				<CheckedBox id="necessary" name="Neccessary" />
				<CheckBox id="preferences" name="Preferences" />
				<CheckBox id="marketting" name="Marketting" />
				<CheckBox id="statistics" name="Statistics   " />
				<button className="accept-cookies btn white-text accept-cookies hide-on-med-and-down">
					Okay
				</button>
			</div>
			<button className="accept-cookies btn white-text accept-cookies hide-on-large-only">
				Okay
			</button>
		</div>
	);
};
export default CookiesBanner;
