import React from "react";

export default function AboutOurServices() {
	return (
		<div className="about-our-services">
			<div className="our-services-head">
				<h3>About our services page</h3>
			</div>
			<div className="our-services-body">
				<div className="our-services-item">
					<div className="services-counter">1.</div>
					<div className="our-services-content">
						<div className="content-header">header</div>
						<div className="content-body">body</div>
					</div>
				</div>
			</div>
		</div>
	);
}
