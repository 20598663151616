const Saves = ({ image, alt, classNames }) => {
	return (
		<div className="carousel-cell" style={{ width: "100%" }}>
			<section className="funfacts">
				<div className="funfacts-left-text">
					<h3>Saves</h3>
				</div>
				<div className="funfacts-center">
					<img width="85%" height="90%" src={image} alt="img1" />
				</div>
				<div className="funfacts-right-text">
					<p>Time and Money, up to 5000+ GHS in fees.</p>
				</div>
			</section>
		</div>
	);
};
export default Saves;
