import React from "react";
import { makeNavLinks } from "../../utilities/makeNavlinks";

import navbarData from "../../../static/navbarData";
import logo from "../../../static/images/logo.png";
import SubscribeBtn from "../../utilities/SubscribeBtn";
const NavLinks = () => {
	return (
		<nav>
			<div className="nav-wrapper">
				<a href="/" className="brand-logo">
					<img className="logo" alt="logo" src={logo} />
				</a>
				<a
					href="#"
					data-target="mobile-demo"
					className="sidenav-trigger "
				>
					{/* <i className="material-icons icon yellow-text text-darken-1">
						menu
					</i> */}
				</a>
				<ul className="right hide-on-med-and-down navbar-links">
					{/* {makeNavLinks(navbarData)} */}
					<a
						className="btn btn-large right black show-on-large nav-btn nav-btn-large"
						href="mailto:info@localstaysz.xcelsz.com"
					>
						Subcribe Now
					</a>
				</ul>

				<a
					className="btn btn-small right black nav-btn nav-btn-small hide-on-large-only"
					href="mailto:info@localstaysz.xcelsz.com"
				>
					Subcribe Now
				</a>
			</div>
		</nav>
	);
};
export default NavLinks;
