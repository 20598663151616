const navbarData = [
	{
		id: 1,
		title: "HOW IT WORKS",
		submenu: [
			"About Our Services",
			"What's Included",
			"About Our Fees",
			"Why It Works",
			"Getting Started",
			"FAQs"
		]
	},
	{
		id: 2,
		title: "PROPERTIES",
		submenu: ["Short Let", "Rent", "Buy", "Invest"]
	},
	{
		id: 3,
		title: "INSIGHTS",
		submenu: ["Where can I", "What can I", "How much Will I"]
	},
	{
		id: 4,
		title: "ABOUT US",
		submenu: [
			"Why Us",
			"Our Terms",
			"Success Stories",
			"Connect With Us",
			"Refer Someone"
		]
	}
];

export default navbarData;
