import React from "react";
import Carousel from "./home/carousel/Carousel";
import Cta from "./home/cta/Cta";
import FunFacts from "./home/funfacts/FunFacts";
import { Link } from "react-router-dom";

export default function Home() {
	return (
		<>
			<Carousel />
			<FunFacts />
			<Cta />
		</>
	);
}
