import React from "react";
//components
import SocialMediaNav from "./SocialMediaNav";
import NavDrop from "./RollDownNav";
import SideNav from "./SideNav";
import NavLinks from "./NavLinks";

const Header = () => {
  return (
    <div className="nav-header">
      {/* <SocialMediaNav /> */}
      <SideNav />
      <NavLinks />
      <NavDrop />
    </div>
  );
};
export default Header;
