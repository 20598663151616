import SubscribeBtn from "../../utilities/SubscribeBtn";
import Typed from "react-typed"
export default function CarouselItem({
	appendedText,
	imgWidth,
	imgHeight,
	imgSrc,
	divClass,
	textClass
}) {
	return (
		<li className="splide__slide">
			<div >
				<img
					style={{ opacity: "0.7" }}
					width={imgWidth}
					height={imgHeight}
					src={imgSrc}
					alt=""
				/>
				<div className={`carousel-text ${textClass}`}>
					<p className="header">
						GETTING A PLACE TO STAY, LIVE AND OR WORK IN CAN <span className="coloured-text"> 
							<Typed
								strings={[
										" BE SIMPLE AND STRESS-FREE ..",
										" SAVE TIME & MONEY, HASSLE-FREE & SAVE",
										" BE HANDS-OFF..",
									]}
									typeSpeed={150}
									backSpeed={100}
									loop
							/>
						</span>
					</p>
					<br />
					<a
						href="mailto:info@localstaysz.xcelsz.com"
						className="btn btn-small btn-carousel-sm hide-on-med-only hide-on-large-only"
					>
						FIND OUT HOW
					</a>
					<a
						href="mailto:info@localstaysz.xcelsz.com"
						className="btn btn-large btn-carousel-lg hide-on-small-only"
					>
						FIND OUT HOW
					</a>
				</div>
			</div>
		</li>
	);
}
