import { StrictMode } from "react";

import ReactDOM from "react-dom";
//Stylesheet
import "./lib/css/materialize.min.css";
import "./lib/css/splide.min.css";
import "./lib/css/flickity.css";
import "./lib/css/carousel.css";
import "./lib/css/funfacts.css";
import "./lib/css/howitworks.css";
import "./lib/css/styles.css";
import App from "./App";
import Loading from "./components/Loading";
const rootElement = document.getElementById("root");

ReactDOM.render(
	<StrictMode>
		<App />
	</StrictMode>,
	rootElement
);
