import SocialNavbar from "./SocialMediaNav";
import SideNav from "./SideNav";
import NavLinks from "./NavLinks";
export default function RollDown() {
  return (
    <div className="roll-down-nav">
      {/* <SocialNavbar /> */}
      <SideNav />
      <NavLinks />
    </div>
  );
}
