export default function ({ id, name, isChecked, isDisabled }) {
	return (
		<div className=" col l2 m6 s12">
			<div className="row">
				<p className="checkbox-text col s6">{name}</p>
				<div className="col s6">
					<input id={id} type="checkbox" className="labelcheck" />
					<label className="labelcheck" htmlFor={id}></label>
				</div>
			</div>
		</div>
	);
}
