import React from "react";
import quote from '../../../static/images/quote.png'
import dark_oval from '../../../static/images/dark_oval.png'
import yellow_oval from '../../../static/images/yellow_oval.png'

import SubscribeBtn from "../../utilities/SubscribeBtn";
export default function Cta() {
	return (
		<div className="cta">
			<div className="cta-container">
				<p className="cta-header header">Quality yet affordable properties made the Norm, not an exception</p>
				<div className="cta-illustration">
					<div className="placeholder-div">
					<img className="quote" src={quote} alt="" />
					<button ><p>Search</p>Best Matched</button>
					</div>
					
				</div>

				<img className="oval dark" src={dark_oval} alt="dark_vector" />
				<img className="oval yellow" src={yellow_oval} alt="yellow_vector" />
			</div>
		</div>
	);
}
