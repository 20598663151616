import React, { Component } from "react";
import { Link } from "react-router-dom";

const makeAccordion = staticData => {
	return staticData.map(accordItem => {
		return (
			<li className="side-menu" key={accordItem.title}>
				<div className="collapsible-header">
					<span> {accordItem.title} </span>
				</div>
				{accordItem.submenu.map(subItem => {
					return (
						<div
							key={accordItem.subItem}
							className="container collapsible-body"
						>
							<Link
								to={`/${subItem
									.replace(/\b(')\b/g, "")
									.replace(/ /g, "-")
									.toLowerCase()}`}
								href="#"
								className="accordion-submenu white-text"
							>
								{subItem}{" "}
							</Link>
						</div>
					);
				})}
			</li>
		);
	});
};

const makeNavLinks = staticData => {
	return staticData.map(navLink => {
		return (
			<span key={navLink}>
				<li>
					<a
						className="dropdown-trigger btn black"
						href="#"
						data-target={navLink.title}
					>
						{navLink.title}
					</a>
				</li>
				<ul
					id={navLink.title}
					className="dropdown-content navbar-dropdown"
				>
					{navLink.submenu.map(submenuItem => {
						return (
							<li key={submenuItem}>
								<Link
									to={`/${submenuItem
										.replace(/\b(')\b/g, "")
										.replace(/ /g, "-")
										.toLowerCase()}`}
									className="dropdown-link"
								>
									{submenuItem}
								</Link>
							</li>
						);
					})}
				</ul>
			</span>
		);
	});
};
export { makeAccordion, makeNavLinks };
